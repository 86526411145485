<template>
    <div>
        <div class="col d-sm-flex justify-content-center">
            <div class="card text-center">
                <div v-if="!this.license.hasExtended">
                    <h2>La tua licenza è scaduta</h2>
                    <p>Cliccando sul seguente bottone puoi estendere la durata di <b>4 giorni</b> a partire da oggi.</p>

                    <b-button variant="success" size="lg" block @click="extendLicense">ESTENDI PERIODO LICENZA</b-button>
                </div>
                <div v-else>
                    <h2>Licenza non più estendibile.</h2>
                    <p>Hai già usufruito dell'estensione della licenza. Contattaci per acquistarne una nuova.</p>

                    <b-button variant="success" size="lg" block @click="$router.push('/products')">Torna ai tuoi prodotti</b-button>
                </div>

                <div class="text-center">
                    <br>
                    <b-alert variant="success" :show="message != ''" fade><b-icon icon="hand-thumbs-up"/> {{ message }}</b-alert>
                    <b-alert variant="danger" :show="errorMsg != ''">{{ errorMsg }}</b-alert>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import ProductLicenseDataService from "@/components/product_license/ProductLicenseDataService";

export default {
    name: "product-license-extend",

    data() {
        return {
            extensionDays: 4,
            license: null,
            productLicenseCode: null,
            message: '',
            errorMsg: ''
        };
    },
    methods: {
        retrieveLicense() {
            console.log(this.productLicenseCode)
            ProductLicenseDataService.getByCode(this.productLicenseCode)
                .then(response => {
                    this.license = response.data;
                    this.message = '';
                    this.errorMsg = '';
                })
                .catch(e => {
                    this.errorMsg = "Nessuna licenza corrispondente!"
                    console.log(e);
                });
        },
        extendLicense() {
            ProductLicenseDataService.extend(this.license.id, this.extensionDays)
                .then(response => {
                    this.$router.push('/products');
                    this.message = '';
                    this.errorMsg = '';
                })
                .catch(e => {
                    this.errorMsg = e.response.data.message;
                    console.log(e);
                });
        },
    },
    mounted() {
        if (this.$route.params.productLicenseCode) {
            this.productLicenseCode = this.$route.params.productLicenseCode;
            this.retrieveLicense();
        }
    }
};
</script>
